<template>
    <div>
        <v-row>
            <v-col cols="12" md="9" sm="9">
                <v-toolbar dense class="tc-title">
                    <v-toolbar-title>Component Wise Report</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
                    <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
                    <v-btn dark text @click="snackbar = false">Close</v-btn>
                </v-snackbar>
                <v-card class="px-6">
                    <v-card-title>
                        {{ company }}
                    </v-card-title>
                    <v-row>
                        <v-checkbox v-model="check_select_all1" @click="select_all_func(heads, 'head')">
                            <template #label>
                                Select All
                            </template>
                        </v-checkbox>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="2" sm="3" v-for="item in heads">
                            <!-- <v-checkbox :id="item.name" v-model="item.checked" color="primary" :label="item.name" :checked="item.checked" @change="checkedHead(item)" style="height:20px;" ></v-checkbox> -->
                            <v-checkbox v-model="item.checked">
                                <template #label>
                                    {{ item.name }}
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <hr />

                    <v-row>
                        <v-checkbox v-model="check_select_all2" @click="select_all_func(heads1, 'head1')">
                            <template #label>
                                Select All
                            </template>
                        </v-checkbox>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="2" sm="3" v-for="item in heads1">
                            <!-- <v-checkbox :id="item.name" v-model="item.checked" color="primary" :label="item.name" :checked="item.checked" @change="checkedHead(item)" style="height:20px;" ></v-checkbox> -->
                            <v-checkbox v-model="item.checked">
                                <template #label>
                                    {{ item.name }}
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <hr />
                    <v-row>
                        <v-checkbox v-model="check_select_all3" @click="select_all_func(heads2, 'head2')">
                            <template #label>
                                Select All
                            </template>
                        </v-checkbox>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="2" sm="3" v-for="item in heads2">
                            <!-- <v-checkbox :id="item.name" v-model="item.checked" color="primary" :label="item.name" :checked="item.checked" @change="checkedHead(item)" style="height:20px;" ></v-checkbox> -->
                            <v-checkbox v-model="item.checked">
                                <template #label>
                                    {{ item.name }}
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>
                    <center><v-btn class="ma-4" color="primary darken-1" @click="retrive(false)">Retrive</v-btn></center>

                </v-card></v-col>
            <v-col cols="12" md="3" sm="3">
                <v-card v-if="headersfinallist.length != 0">
                    <v-simple-table class="elevation-1">

                        <thead>
                            <tr>

                                <th class="text-left"> Name </th>
                                <th class="text-left"> Sequence</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style="cursor:all-scroll;" v-for="item in headersfinallist" :key="item.index"
                                draggable="true" @dragstart="start" @dragover="dragover(item)">

                                <td>{{ item.name }}</td>
                                <td><v-text-field placeholder="Sort Order" type="number" v-model="item.sortorder"
                                        :rules="[v => !!v || 'required']"></v-text-field></td>
                            </tr>
                        </tbody>

                    </v-simple-table>
                    <v-row class='pl-4'>
                        <v-col sm="12">
                            <center><v-btn class="mt-6" color="primary darken-1" @click="fetchReport()">Fetch</v-btn>
                            </center>
                        </v-col>
                    </v-row>
                </v-card></v-col>
        </v-row>









        <br>

        <br />
        <div>
            <v-data-table id="exceltable" :headers="headers" :items="learnerlist" class="elevation-1" :search="search">
                <template v-slot:top class="red">
                    <v-toolbar flat color="white">
                        <v-row>
                            <v-toolbar-title>Application Received List</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-btn v-if="learnerlist.length != 0" @click="opendialog()" color="success" dark
                                class="mb-2">attachments</v-btn>

                            <v-spacer></v-spacer>
                            <v-text-field v-model="search" append-icon="search" label="Search" single-line
                                hide-details></v-text-field>
                            <v-btn v-if="learnerlist.length != 0" @click="exportexcel()" color="success" dark
                                class="mb-2"><v-icon dark>mdi-file-excel</v-icon></v-btn>
                        </v-row>
                    </v-toolbar>
                </template>
            </v-data-table>


        </div>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-dialog width="600" persistent v-model="dialog1">
            <v-card>
                <v-card-title class="c-title">Select Question to download Answers attachments</v-card-title>
                <v-card-text>
                    <v-col v-for="item in questionarray" :key="item.question">
                        <v-checkbox :id="item.question_id" v-model="item.isselected" color="primary" :label="item.question"
                            :checked="item.isselected" style="height:20px;"></v-checkbox>
                    </v-col>
                    <br>


                    <!-- <span v-for="(i,index) in questionarray" :key=index>          
               {{i.question}} <br><br></span>
                      <v-row>
                          <v-btn @click="reschedulecompanyoffering()" dark color="blue">Copy</v-btn>
                      </v-row> -->
                </v-card-text>
                <v-card-actions>
                    <v-row style="margin-left:50px;">
                        <v-btn @click="downloadanswers()" dark color="primary">Download</v-btn>
                        <v-btn style="margin-left:250px;" @click="dialog1 = false" text>Cancel</v-btn>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from "axios";
import { table2excel } from "../jquery.table2excel";
import readXlsxFile from "read-excel-file";
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';


export default {

    data: () => ({
        overlay: false,
        snackbar_msg: "",
        color: "",
        snackbar: false,
        search: "",
        companyfilename: "",
        dialog1: false,
        companycode: "",
        heads: [],
        heads1: [],
        heads2: [],
        company: null,
        scheduling_id: null,
        learnerlist: [],
        headers: [],
        headerslist: [],
        headersfinallist: [],
        questionarray: [],
        selectall: false,
        selectallnew: false,
        selectallnew1: false,
        row: "",

        //yash
        check_select_all1: false,
        check_select_all2: false,
        check_select_all3: false,
        head_list: {}

    }),

    mounted() {
        this.scheduling_id = this.$route.params.scheduling_id;
        if (this.scheduling_id)
            localStorage.setItem("scheduling_id", this.scheduling_id);
        if (!this.scheduling_id)
            this.scheduling_id = localStorage.getItem("scheduling_id");
        const data = {
            scheduling_id: this.scheduling_id,
        };
        axios
            .post("/OnCampusProcess/getCompanyApplicationListComponentWise", data)
            .then(res => {
                if (res.data.msg == "200") {
                    this.heads = res.data.heads;
                    this.heads1 = res.data.heads1;
                    this.heads2 = res.data.heads2;
                    // this.addInHeaderList(this.heads);
                    // this.addInHeaderList(this.heads1);
                    // this.addInHeaderList(this.heads2);
                    this.company = res.data.company;
                    this.companycode = res.data.companycode;
                    this.retrive(true);
                } else {
                    //console.log("error fetching data!");
                }
            });
    },

    methods: {

        select_all_func(head_value, head_name) {

            if (head_name == 'head') {
                for (var i in head_value) {
                    if (this.check_select_all1) {
                        head_value[i].checked = true;
                    }
                    else {
                        head_value[i].checked = false;
                    }
                }
            }
            if (head_name == 'head1') {
                for (var i in head_value) {
                    if (this.check_select_all2) {
                        head_value[i].checked = true;
                    }
                    else {
                        head_value[i].checked = false;
                    }
                }
            }
            if (head_name == 'head2') {
                for (var i in head_value) {
                    if (this.check_select_all3) {
                        head_value[i].checked = true;
                    }
                    else {
                        head_value[i].checked = false;
                    }
                }
            }
        },


        start() {
            this.row = event.target;
        },
        dragover(item) {

            var e = event;
            e.preventDefault();

            let children = Array.from(e.target.parentNode.parentNode.children);

            if (children.indexOf(e.target.parentNode) > children.indexOf(this.row))
                e.target.parentNode.after(this.row);
            else
                e.target.parentNode.before(this.row);

            item.sortorder = children.indexOf(this.row) + 1;

        },
        retrive(defaultcomponent) {
            var title_list = []
            for (var x in this.heads) {
                if (this.heads[x].checked)
                    title_list.push(this.heads[x].name);
            }
            for (var x in this.heads1) {
                if (this.heads1[x].checked)
                    title_list.push(this.heads1[x].name);
            }
            for (var x in this.heads2) {
                if (this.heads2[x].checked)
                    title_list.push(this.heads2[x].name);
            }

            if (title_list.length == 0) {
                this.showSnackbar("red", "Please select Fields First !!");
                return;
            }

            this.overlay = true;
            const data = {
                heads: title_list,
                defaultcomponent: defaultcomponent,
                scheduling_id: this.scheduling_id,
            };
            axios
                .post("OnCampusProcess/getCompanyApplicationListComponentWiseFilternew", data)
                .then((res) => {
                    this.overlay = false;
                    if (res.data.msg == "200") {
                        this.headersfinallist = res.data.headersfinallist;
                    } else {
                        this.showSnackbar("red", res.data.error);
                    }
                }).catch((error) => {
                    this.overlay = false;
                    window.console.log(error);
                }).finally(() => {
                    this.overlay = false;
                });

        },

        opendialog() {
            const data = {
                company: this.company,
            };
            axios
                .post("/StudentApplicationTrack/getattchmentquestions", data)
                .then((res) => {
                    //console.log(res.data);
                    if (res.data.msg == "success") {
                        this.questionarray = res.data.questionarray
                        this.dialog1 = true
                    } else {
                        this.showSnackbar("#b71c1c", res.data.msg);// show snackbar
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", error); // show snackbar
                    // window.alert(error)
                })
        },

        downloadanswers() {
            const data = {
                questionarray: this.questionarray,
            };
            axios.post("/StudentApplicationTrack/downloadanswers", data).then((res) => {
                if (res.data.msg == "success") {
                    //console.log(res.data.answerlist);
                    const zip = new JSZip();
                    let count = 0;
                    const zipFilename = "Answers.zip";
                    var ans = res.data.answerlist;
                    ans.forEach(async function (url) {
                        JSZipUtils.getBinaryContent(url.url, function (err, data) {
                            if (err) {
                                //console.log(err);
                                throw err;
                                // or handle the error
                            }
                            zip.file(url.grno, data, { binary: true });
                            count++;
                            if (count === res.data.answerlist.length) {
                                zip.generateAsync({ type: 'blob' }).then(function (content) {
                                    saveAs(content, zipFilename);
                                });
                            }
                        });

                    });

                    this.dialog1 = false;
                }
            });
        },



        fetchReport() {
            this.learnerlist = []
            if (this.headersfinallist.length == 0) {
                this.showSnackbar("red", "Please select Fields First !!");
            } else {
                for (var i = 0; i < this.headersfinallist.length; i++) {
                    if (this.headersfinallist[i].sortorder == null || this.headersfinallist[i].sortorder == "") {
                        this.showSnackbar("red", "Please Enter Sort Order");
                        break;
                    }
                }
                this.overlay = true;
                const data = {
                    scheduling_id: this.scheduling_id,
                    heads: this.headersfinallist,
                };
                axios
                    .post("OnCampusProcess/getCompanyApplicationListComponentWiseDetails", data)
                    .then((res) => {
                        this.overlay = false;
                        if (res.data.msg == "200") {
                            this.learnerlist = res.data.learnerlist;
                            if (this.learnerlist.length < 1) {
                                this.showSnackbar("red", "Data Not Found");
                            } else {
                                this.headers = [];
                                this.headers.push({ text: 'Sr.No.', value: 'srno', align: 'left', sortable: true });
                                for (var i = 0; i < res.data.finalheadlist.length; i++) {
                                    var temp = { text: res.data.finalheadlist[i].name, value: res.data.finalheadlist[i].name, align: 'left', sortable: true };
                                    this.headers.push(temp);
                                }
                            }
                        } else {
                            this.showSnackbar("red", res.data.error);
                        }
                    }).catch((error) => {
                        this.overlay = false;
                        window.console.log(error);
                    }).finally(() => {
                        this.overlay = false;
                    });
            }
        },

        exportexcel() {
            //  alert("hi"+this.company) 
            $("#exceltable").table2excel({
                name: "Worksheet Name",
                filename: this.companycode,
                fileext: ".xls"
            });
        },

        selectAll() {
            this.selectall = !this.selectall;
            //console.log(this.selectall)
            if (this.selectall) {
                this.headerslist = []
                for (var i = 0; i < this.heads.length; i++) {
                    if (!this.headerslist.includes(this.heads[i].name))
                        this.headerslist.push(this.heads[i].name)
                    this.heads[i].checked = true;
                    document.getElementById(this.heads[i].name).checked = true;
                }
            } else {
                this.headerslist = []
                for (var i = 0; i < this.heads.length; i++) {
                    this.heads[i].checked = false;
                    document.getElementById(this.heads[i].name).checked = false;
                }
            }
        },

        selectAllnew() {
            this.selectallnew = !this.selectallnew;
            //console.log(this.selectallnew)
            if (this.selectallnew) {
                this.headerslist = []
                for (var i = 0; i < this.heads1.length; i++) {
                    if (!this.headerslist.includes(this.heads1[i].name))
                        this.headerslist.push(this.heads1[i].name)
                    this.heads1[i].checked = true;
                    document.getElementById(this.heads1[i].name).checked = true;
                }
            } else {
                this.headerslist = []
                for (var i = 0; i < this.heads1.length; i++) {
                    this.heads1[i].checked = false;
                    document.getElementById(this.heads1[i].name).checked = false;
                }
            }
        },
        selectAllnew1() {
            this.selectallnew1 = !this.selectallnew1;
            //console.log(this.selectallnew1)
            if (this.selectallnew1) {
                this.headerslist = []
                for (var i = 0; i < this.heads2.length; i++) {
                    if (!this.headerslist.includes(this.heads2[i].name))
                        this.headerslist.push(this.heads2[i].name)
                    this.heads2[i].checked = true;
                    document.getElementById(this.heads2[i].name).checked = true;
                }
            } else {
                this.headerslist = []
                for (var i = 0; i < this.heads2.length; i++) {
                    this.heads2[i].checked = false;
                    document.getElementById(this.heads2[i].name).checked = false;
                }
            }
        },


        checkedHead(item) {
            if (item.checked) {
                if (!this.headerslist.includes(item.name))
                    this.headerslist.push(item.name)
            } else {
                for (var i = 0; i < this.headerslist.length; i++) {
                    if (this.headerslist[i] == item.name) {
                        this.headerslist.splice(i, 1);
                        break;
                    }
                }
            }
        },

        checkedHead(item) {
            if (item.checked) {
                if (!this.headerslist.includes(item.name))
                    this.headerslist.push(item.name)
            } else {
                for (var i = 0; i < this.headerslist.length; i++) {
                    if (this.headerslist[i] == item.name) {
                        this.headerslist.splice(i, 1);
                        break;
                    }
                }
            }
        },

        addInHeaderList(heads) {
            for (var i = 0; i < heads.length; i++) {
                if (heads[i].checked && !this.headerslist.includes(heads[i].name))
                    this.headerslist.push(heads[i].name)
            }
        },

        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
    }
};
</script>
<style scoped>
.required {
    color: red;
    font-size: 14px;
    font-style: bold;
}

.tc-title {
    background-image: linear-gradient(-90deg, #fff, #057996);
    color: #fff;
    border-radius: 3px;
}
</style>    